import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import TextField from '@material-ui/core/TextField';
import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	textField: {
		width: '60%', 
		height: '100%', 
		marginTop: '17px',
		alignItems: "center",
		textAlign: 'center',
	}
}));

export default function NumberSelector(props) {
	const classes = useStyles();
	const { onChange, defaultValue, increment } = props;
	const [ value, setValue ] = useState(defaultValue);
	useEffect(() => onChange(value), [value]);
	return (
		<div style={props.style}>
			<IconButton style={{width: '20%'}} onFocus={(event) => event.stopPropagation()} onClick={(e) => {e.stopPropagation(); setValue(Math.round((value - increment) * 100) / 100)}}>
				<RemoveIcon />
			</IconButton>

			<TextField onClick={(e) => e.stopPropagation()} onFocus={(event) => event.stopPropagation()} style={{width: '60%', height: '100%', marginTop: '17px', alignItems: "center", textAlign: 'center',}} inputProps={{style: { textAlign: 'center' }}} value={value} type="number" onChange={({ target }) => {
				const newValue = target.value;
				setValue(Math.round(newValue * 100) / 100);

			}} />

			<IconButton style={{width: '20%', height: 'auto'}} onFocus={(event) => event.stopPropagation()} onClick={(e) => {e.stopPropagation(); setValue(Math.round((value + increment) * 100) / 100) }}>
				<AddIcon />
			</IconButton>
		</div>
	);
}
