import CachedIcon from "@material-ui/icons/Cached";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	loadingWrapper: {
		margin: theme.spacing(3),
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	loadingText: {
		marginLeft: theme.spacing(1)
	}
}));

export default function Loading(props) {
	const { listItem } = props;
	const classes = useStyles();
	let oApostropheS = "o";
	const num = Math.floor(Math.random() * 10);
	let i = 1;
	while (i < num) {
		oApostropheS = oApostropheS + "o";
		i++;
	}
	if (listItem) {
		return (
			<ListItem>
				<ListItemIcon>
					<CachedIcon />
				</ListItemIcon>
				<ListItemText primary={"L" + oApostropheS + "ading..."} />
			</ListItem>
		);
	}
	return (
		<div className={classes.loadingWrapper}>
			<CachedIcon />
			<span className={classes.loadingText}>L{oApostropheS}ading...</span>
		</div>
	);
}
