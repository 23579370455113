import Autocomplete from "@material-ui/lab/Autocomplete";
import Avatar from "@material-ui/core/Avatar";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	personPickerWrapper: {
		width: "80%"
	},
	smallAvatar: {
		width: theme.spacing(3),
		height: theme.spacing(3),
		marginRight: theme.spacing(1)
	},
	fullWidth: {
		width: "100%"
	}
}));

/*
 *
 * People example:
 * const people = [{name: "steve", imageSrc: "Steve.jpeg"}, {name: "Jacob Kenody", imageSrc: "Jacob Kenody.jpeg"}]
 *
 */
export default function PersonPicker(params) {
	const classes = useStyles();
	let {
		people,
		defualtPeople,
		label,
		variant,
		onChange,
		fullWidth = false,
		noOptionsText = "No people found :(",
		teamNumber,
		...rest
	} = params;
	const [value, setValue] = React.useState(defualtPeople || []);
	const [team, setTeam] = React.useState(teamNumber || []);

	if (team !== teamNumber) {
		setTeam(teamNumber);
		setValue(defualtPeople || []);
	};

	/* Remove defualt options from people */
	/*for (var i = people.length - 1; i >= 0; i--) {
		for (var j = 0; j < defualtPeople.length; j++) {
			if (people[i] && people[i].name === defualtPeople[j].name) {
				people.splice(i, 1);
			}
		}
	}*/

	return (
		<div
			className={
				classes.personPickerWrapper + fullWidth
					? " " + classes.fullWidth
					: ""
			}
		>
			<Autocomplete
				className={fullWidth ? classes.fullWidth : undefined}
				onChange={(event, selectedOptions) => {
					onChange(event, selectedOptions);
					setValue(selectedOptions);
				}}
				disableCloseOnSelect
				disableClearable
				multiple
				selectOnFocus
				filterSelectedOptions
				id="team-student-selection"
				noOptionsText={noOptionsText}
				options={people}
				value={value}
				getOptionLabel={(option) => option.name}
				getOptionSelected={(option, value) =>
					value.name === option.name
				}
				renderInput={(paramaters) => (
					<TextField
						variant={variant}
						label={label}
						placeholder={label}
						fullWidth={fullWidth}
						{...paramaters}
						{...rest}
					/>
				)}
				renderOption={(option, { selected }) => (
					<React.Fragment>
						<Avatar
							className={classes.smallAvatar}
							alt={option.name}
							src={option.imageSrc}
						/>
						{option.name}
					</React.Fragment>
				)}
				renderTags={(tagValue, getTagProps) => {
					return tagValue.map((option, index) => (
						<Chip
							{...getTagProps({ index })}
							label={option.name}
							avatar={
								<Avatar
									alt={option.name}
									src={option.imageSrc}
								/>
							}
						/>
					));
				}}
			/>
		</div>
	);
}
