import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

export default function ConfirmDialouge(props) {
	const {
		open,
		setOpen,
		onConfirm,
		onCancel,
		title,
		body,
		confirmButtonText,
		cancelButtonText
	} = props;
	return (
		<Dialog
			open={open}
			onClose={() => {
				setOpen(false);
			}}
		>
			<DialogTitle>{title ? title : "Are you sure?"}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{body
						? body
						: "This is a dangerous action. Are you sure that you wish to continue?"}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={(e) => {
						setOpen(false);
						onCancel(e);
					}}
					color="primary"
					variant="outlined"
				>
					{cancelButtonText ? cancelButtonText : "Cancel"}
				</Button>
				<Button
					onClick={(e) => {
						setOpen(false);
						onConfirm(e);
					}}
					color="secondary"
					variant="contained"
					autoFocus={true}
				>
					{confirmButtonText ? confirmButtonText : "Confirm"}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
