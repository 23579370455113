import { Sparklines, SparklinesLine } from "react-sparklines";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography"; 
import clsx from "clsx";
import formatNumber from "../utils/format-number.js";
 
const useStyles = makeStyles((theme) => ({
	figureContainer: {
		padding: theme.spacing(2),
		minWidth: 140,
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		display: "inline-block",
		height: "auto"
	},
	figureRow: {
		display: "flex",
		flexWrap: "wrap",
		"& > $figureContainer": {
			marginRight: theme.spacing(3)
		}
	},
	figureTitle: {
		fontSize: "0.8rem"
	},
	figureValue: { fontSize: "2.8rem" },
	bigFigureValue: {
		fontSize: "3.3rem"
	},
	figureChart: {
		width: "100%"
	}
}));

export default function Figure(props) {
	const classes = useStyles();
	const { title, value, big, currency, chartData, children } = props;
	const theme = useTheme();
	let lastChartItem;
	let secondToLastChartItem;
	if (chartData) {
		lastChartItem = chartData[chartData.length - 1];
		secondToLastChartItem = chartData[chartData.length - 2];
	}

	return (
		<Paper elevation={3} className={classes.figureContainer}>
			{title && (
				<Typography
					className={classes.figureTitle}
					color="textSecondary"
					gutterBottom
				>
					{title}
				</Typography>
			)}
			{(value === 0 || value) && (
				<Typography
					variant="h3"
					className={
						big ? classes.bigFigureValue : classes.figureValue
					}
				>
					{typeof value === "number"
						? formatNumber(value, currency)
						: value}
				</Typography>
			)}
			{chartData && (
				<Sparklines
					className={classes.figureChart}
					data={chartData}
					strokeWidth={1}
					limit={50}
					width={"20%"}
				>
					<SparklinesLine
						color={
							chartData.length < 2
								? "black"
								: lastChartItem == secondToLastChartItem
								? "black"
								: lastChartItem > secondToLastChartItem
								? "green"
								: "red"
						}
						style={{ fill: "none" }}
					/>
				</Sparklines>
			)}
			{children}
		</Paper>
	);
}

export function FigureRow(props) {
	const classes = useStyles();
	const { children } = props;
	return <div className={classes.figureRow}>{children}</div>;
}
