import {
	Button,
	Fade,
	FormControl,
	Grid,
	Input,
	InputAdornment,
	MenuItem,
	Paper,
	Select,
	Snackbar,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";
import AppContext from "../../context";
import NProgress from "nprogress";
import React from "react";
import formatNumber from "../../utils/format-number";
import { makeStyles } from "@material-ui/core/styles";
import callAPI from "../../utils/api.js";

const useStyles = makeStyles((theme) => ({
	classPage: {
		marginTop: theme.spacing(6),
		marginInline: theme.spacing(6),
		color: theme.palette.type === "dark" ? "white" : "black",
	},
	classSection: {
	},
	classSectionItem: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	fab: {
		position: "fixed",
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
}));

const productNumbers = [1, 2, 3, 4];

const months = ["Not started", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export default function Bank(props) {
	const { teamInfo, updateTeamInfo } = props;
	const classes = useStyles();
	const { user, setUser } = React.useContext(AppContext);
	const [error, setError] = React.useState("");
	const [severity, setSeverity] = React.useState("");
	const [open, setOpen] = React.useState(false);
	const [amount, setAmount] = React.useState(0);
	const [sender, setSender] = React.useState();
	const [recipient, setRecipient] = React.useState();

	const [purchaseAmount, setPurchaseAmount] = React.useState();

	const handlePurchaseChange = (event) => {
		setPurchaseAmount(event.target.value);
	};
	const theme = useTheme();
	const onDesktop = useMediaQuery(theme.breakpoints.up("md"));

	function purchase() {
		if (sender === undefined) {
			setError("Select which store you want to send the money from.");
			setSeverity("error");
			setOpen(true);
			return;
		}
		if (recipient === undefined) {
			setError("Select which store you want to recieve the money.");
			setSeverity("error");
			setOpen(true);
			return;
		}
		if (amount === undefined || isNaN(amount) || amount < 1) {
			setError("Select the amount of money that you wish to transfer.");
			setSeverity("error");
			setOpen(true);
			return;
		}
		NProgress.start();
		console.log("sending request");
		callAPI(
			"BankTransfer",
			user.token,
			{ sender: sender, recipient: recipient, amount: amount },
			function (err, res, xhr) {
				NProgress.done();
				if (!err) {
					setError("Transfer successful!");
					setSeverity("success");
					setOpen(true);
					updateTeamInfo();
					return;
				} else {
					setError(res.reason);
					setSeverity("error");
					setOpen(true);
					return;
				}
			}
		);
	}

	const handleClose = (event, reason) => {
		setOpen(false);
	};

	return (
		<Fade in timeout={500}>
			<div height="100%" width="100%" className={classes.classPage}>
				<Typography variant="h4" style={{ marginBottom: "20px" }}>
					Manage bank accounts
				</Typography>
				<Grid container spacing={2} direction={onDesktop ? "row" : "row-reverse"}>
					<Grid item xs={12} md={6}>
						<Paper
							style={{
								paddingTop: "20px",
								paddingBottom: "20px",
								paddingLeft: "30px",
							}}
						>
							<Typography style={{ marginBottom: "10px" }} variant="h6">
								Transfer money:
							</Typography>
							{Object.keys(teamInfo.stores).length !== 1 ? (
								<Grid container direction="column" spacing={1}>
									<Grid item>
										<div>
											From:{" "}
											<Select onChange={(info, value) => setSender(value.props.value)}>
												{Object.keys(teamInfo.stores).map((store) => (
													<MenuItem value={store}>Store {store}</MenuItem>
												))}
											</Select>
										</div>
									</Grid>

									<Grid item>
										<div>
											To:{" "}
											<Select onChange={(info, value) => setRecipient(value.props.value)}>
												{Object.keys(teamInfo.stores).map((store) => (
													<MenuItem value={store}>Store {store}</MenuItem>
												))}
											</Select>
										</div>
									</Grid>
									<Grid item>
										<div>
											Amount:{" "}
											<FormControl
												style={{
													width: "100px",
													margin: "3px",
													marginTop: "-5px",
												}}
												variant="standard"
											>
												<Input
													id="outlined-adornment-amount"
													type="number"
													defaultValue={0}
													required
													onChange={(event) => {
														setAmount(event.target.valueAsNumber);
													}}
													startAdornment={<InputAdornment position="start">$</InputAdornment>}
													labelWidth={60}
												/>
											</FormControl>
										</div>
									</Grid>
									<Grid item>
										{Object.keys(teamInfo.stores).length !== 1 && (
											<div>
												Note: the transfer will appear in your cost breakdown under bank modifier.
											</div>
										)}
									</Grid>
									<Grid item>
										<Button variant="contained" color="primary" onClick={purchase}>
											Transfer
										</Button>
									</Grid>
								</Grid>
							) : (
								<Grid item>
									<Typography variant="body1">
										Get at least 2 stores to manage your bank accounts!
									</Typography>
								</Grid>
							)}
						</Paper>
					</Grid>
					<Grid item xs={12} md={6}>
						<Paper
							style={{
								paddingTop: "20px",
								paddingBottom: "20px",
								paddingLeft: "30px",
								paddingRight: "40px",
							}}
						>
							<Typography style={{ marginBottom: "10px" }} variant="h6">
								Current bank balances:
							</Typography>
							{Object.keys(teamInfo.stores).map((store) => (
								<div style={{ display: "flex" }}>
									<Typography variant="body1">Store {store}:</Typography>
									<Typography style={{ marginLeft: "auto" }} variant="body1">
										{formatNumber(
											teamInfo.stores[store].outcomes[
												Math.max(...Object.keys(teamInfo.stores[store].outcomes))
											].Bank +
											(teamInfo.stores[store].modifiers[
												Math.max(...Object.keys(teamInfo.stores[store].outcomes)) + 1
											]
												? teamInfo.stores[store].modifiers[
													Math.max(...Object.keys(teamInfo.stores[store].outcomes)) + 1
												].Bank
												: 0),
											true
										)}
									</Typography>
								</div>
							))}
						</Paper>
					</Grid>
				</Grid>

				<Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
					<Alert severity={severity} onClose={handleClose}>
						{error}
					</Alert>
				</Snackbar>
			</div>
		</Fade>
	);
}
