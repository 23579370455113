import AppContext from ".";
import React from "react";

const ContextProvider = ({ children }) => {
	const [user, setUser] = React.useState(localStorage.WCommerceSignInPersistence ? JSON.parse(localStorage.WCommerceSignInPersistence) : {
		signedIn: false,
		new: true,
		teacher: false,
		token: undefined,
		email: undefined,
		imageUrl: undefined,
		firstName: undefined,
		lastName: undefined,
		prefix: undefined
	});
	const context = {
		setUser,
		user
	};

	React.useEffect(() => {
		localStorage.WCommerceSignInPersistence = JSON.stringify(user)
	}, [user])

	window.user = user;
	window.setUser = setUser;

	return (
		<AppContext.Provider value={context}>{children}</AppContext.Provider>
	);
};
export default ContextProvider;
