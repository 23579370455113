import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";

import Alert from "@material-ui/lab/Alert";
import AppContext from "../../context";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import React from "react";
import RelatedHandbookSections from "../../components/handbook-viewer.js";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import NProgress from "nprogress";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import formatNumber from "../../utils/format-number";
import { makeStyles } from "@material-ui/core/styles";
import callAPI from "../../utils/api.js";
import { Fade } from "@material-ui/core";

function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarExport style={{ marginLeft: "auto" }} />
		</GridToolbarContainer>
	);
}

const useStyles = makeStyles((theme) => ({
	classPage: {
		color: theme.palette.type === "dark" ? "white" : "black",
		height: "100%",
	},
	dataGridWrapper: {
		width: "100%",
		height: theme.spacing(85),
		flex: 1,
		overflow: "auto",
	},
	dataGrid: { overflow: "auto" },
}));

const productNumbers = [1, 2, 3, 4];

const months = ["Not started", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export default function MarketResearch(props) {
	const { teamInfo, updateTeamInfo } = props;
	const classes = useStyles();
	const { user, setUser } = React.useContext(AppContext);
	const [typeToPurchase, setTypeToPurchase] = React.useState("Simple");
	const [storeToCharge, setStoreToCharge] = React.useState(Object.keys(teamInfo.stores)[0]);
	const [error, setError] = React.useState("");
	const [severity, setSeverity] = React.useState("");
	const [open, setOpen] = React.useState(false);
	const marketResearch = teamInfo.marketResearch;
	const [tab, setTab] = React.useState(
		Object.keys(marketResearch).includes(
			Math.max(...Object.keys(teamInfo.stores[Object.keys(teamInfo.stores)[0]].outcomes)).toString()
		)
			? Object.keys(marketResearch).length - 1
			: Object.keys(marketResearch).length
	);
	const handleChange = (event, newValue) => {
		setTab(newValue);
	};
	var month = Object.keys(marketResearch)[tab];

	var cost = 0;

	if (typeToPurchase === "Comprehensive") {
		cost = 1500;
	}

	if (typeToPurchase === "Simple") {
		cost = 500 * Object.keys(teamInfo.stores).length;
	}

	function purchase() {
		if (typeToPurchase === undefined) {
			setError("Select what type of market research you want to buy.");
			setSeverity("error");
			setOpen(true);
			return;
		}
		NProgress.start();
		console.log("sending request");
		callAPI(
			"PurchaseMarketResearch",
			user.token,
			{ store: storeToCharge, type: typeToPurchase },
			function (err, res, xhr) {
				NProgress.done();
				if (!err) {
					setError("Market research purchased!");
					setSeverity("success");
					setOpen(true);
					updateTeamInfo();
					return;
				} else {
					setError(res.reason);
					setSeverity("error");
					setOpen(true);
					return;
				}
			}
		);
	}

	let productsSold = {};

	let store;

	for (store in teamInfo.stores) {
		let month1;
		for (month1 in teamInfo.stores[store].outcomes) {
			if (productsSold[month1] === undefined) {
				productsSold[month1] = [];
			}
			productsSold[month1].push(teamInfo.stores[store].outcomes[month1].Product1);
			productsSold[month1].push(teamInfo.stores[store].outcomes[month1].Product2);
			productsSold[month1].push(teamInfo.stores[store].outcomes[month1].Product3);
			productsSold[month1].push(teamInfo.stores[store].outcomes[month1].Product4);
		}
	}

	const handleClose = (event, reason) => {
		setOpen(false);
	};

	let cols;
	let rows;

	if (month !== undefined) {
		cols = [
			{ field: "product", headerName: "Product", width: 180 },
			{
				field: "quantityDemanded",
				headerName: "Quantity Demanded",
				width: 210,
			},
			{
				field: "quantitySupplied",
				headerName: "Quantity Supplied",
				width: 200,
			},
			{
				field: "shortage",
				headerName: "Shortage (-) / Surplus (+)",
				width: 240,
			},
			{ field: "sellers", headerName: "Number of sellers", width: 200 },
			{
				field: "isSelling",
				headerName: "Selling?",
				width: 160,
				renderCell: (params) => (params.value ? <CheckIcon /> : <CloseIcon />),
			},
		];

		rows = Object.keys(marketResearch[month]).map((key, i) => {
			return {
				id: i,
				product: key,
				quantityDemanded: marketResearch[month][key].demand,
				quantitySupplied: marketResearch[month][key].supplied,
				shortage: marketResearch[month][key].supplied - marketResearch[month][key].demand,
				sellers: marketResearch[month][key].sellers,
				isSelling: productsSold[month].includes(key),
			};
		});
	}

	console.log("rows", rows);

	console.log("month", month);

	return (
		<Fade in timeout={500}>
			<div className={classes.classPage}>
				{/* <Typography variant="h4" style={{ marginBottom: "20px" }}>
				Market research
			</Typography> */}

				<Tabs value={tab} onChange={handleChange}>
					{Object.keys(marketResearch).map((month) => (
						<Tab label={months[month]} key={month} />
					))}
					{!Object.keys(marketResearch).includes(
						Math.max(...Object.keys(teamInfo.stores[Object.keys(teamInfo.stores)[0]].outcomes)).toString()
					) ? (
						<Tab
							label={
								months[Math.max(...Object.keys(teamInfo.stores[Object.keys(teamInfo.stores)[0]].outcomes))]
							}
						/>
					) : (
						"no"
					)}
				</Tabs>

				{/* <Button onClick={() => {
					// Encode the data as a CSV
					var csv = parse({ data: rows, fields: cols });
				}} */}

				<div style={{ paddingInline: "16px" }}>
					{Math.max(...Object.keys(teamInfo.stores[Object.keys(teamInfo.stores)[0]].outcomes)) == 0 ? (
						<Typography style={{ margin: "40px" }} variant="h4">
							No market research was found before January
						</Typography>
					) : month !== undefined ? (
						<Paper className={classes.dataGridWrapper}>
							<DataGrid
								className={classes.dataGrid}
								rowHeight="35"
								rows={rows}
								columns={cols}
								pageSize={20}
								components={{
									Toolbar: CustomToolbar,
								}}
							/>
						</Paper>
					) : (
						<Grid container spacing={2}>
							<Grid item sx={12} md={6}>
								<Paper
									style={{
										paddingTop: "20px",
										paddingBottom: "20px",
										paddingLeft: "40px",
									}}
								>
									<Grid container direction="column" spacing={1}>
										<Grid item>
											<Typography variant="h6">Purchase</Typography>
										</Grid>
										<Grid item>
											<div>
												I want{" "}
												<Select
													onChange={(info, value) => setTypeToPurchase(value.props.value)}
													autoWidth
													value={typeToPurchase}
												>
													<MenuItem value="Simple">Simple</MenuItem>
													<MenuItem value="Comprehensive">Comprehensive</MenuItem>
												</Select>{" "}
												market research.
											</div>
										</Grid>
										<Grid item>
											{Object.keys(teamInfo.stores).length !== 1 && (
												<div>
													Charge the cost to store{" "}
													<Select
														value={storeToCharge}
														onChange={(info, value) => setStoreToCharge(value.props.value)}
													>
														{Object.keys(teamInfo.stores).map((store) => (
															<MenuItem value={store} key={store}>
																{store}
															</MenuItem>
														))}
													</Select>
												</div>
											)}
										</Grid>
										<Grid item>
											{Object.keys(teamInfo.stores).length !== 1 && (
												<div>
													Note: the charge will appear in your cost breakdown under
													bank modifier.
												</div>
											)}
										</Grid>
										<Grid item>
											<Button variant="contained" color="primary" onClick={purchase}>
												Purchase for {formatNumber(cost, true)}
											</Button>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
							<Grid item sx={12} md={6}>
								<RelatedHandbookSections
									related={[
										{ chapter: "Marketing", section: "Products" },
										{
											chapter: "Marketing",
											section: "Market Research",
										},
									]}
								/>
							</Grid>
						</Grid>
					)}
				</div>

				<Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
					<Alert severity={severity} onClose={handleClose}>
						{error}
					</Alert>
				</Snackbar>
			</div>
		</Fade >
	);
}
