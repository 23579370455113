import React from "react";

function callCallback(callback, err, res, xhr) {
	try {
		callback(err, res, xhr);
	} catch (e) {
		console.warn("Your callback code broke. Error below.");
		console.error(e);
		console.warn("Your callback code broke. Error above.");
	}
}

/**
 * Function that is called when the request completes.
 *
 * @callback requestCallback
 * @param {bool} err - Weather or not the request errored
 * @param {object} data - The response data from the API. Empty if err is true.
 * @param {object} xhr - The XMLHttpRequest object used to send the request.
 */

/**
 * Custom hook to make a request to the API.
 *
 * @param {string} command - The command to send to the API.
 * @param {string} token - The user token to authentiacte with.
 * @param {object} data - The data to send with the request.
 * @param {requestCallback} callback - Function that is called when the request completes.
 * @param {boolean} [onlyFirstRender=false] - Weather the request should only be made the first time a component renders.
 */

export default function useDoStuffWithTheApi(
	command,
	token,
	data = {},
	callback
) {
	//token = "luke";
	function request() {
		try {
			let xhr = new XMLHttpRequest();
			xhr.open("POST", "/API", true);
			xhr.setRequestHeader("Content-Type", "application/json");
			xhr.onreadystatechange = function () {
				if (xhr.readyState === 4) {
					if (xhr.status === 200) {
						try {
							const json = JSON.parse(xhr.responseText);
							if (
								json.result === "Failure" ||
								json.result !== "Success"
							) {
								if (json.reason === "Invalid token") {
									const emptyUser = {
										signedIn: false,
										new: true,
										teacher: false,
										token: undefined,
										email: undefined,
										imageUrl: undefined,
										firstName: undefined,
										lastName: undefined,
										prefix: undefined
									}
									localStorage.WCommerceSignInPersistence = JSON.stringify(emptyUser)
									window.setUser(emptyUser)
								}
								console.log(json);
								console.log(
									"%cBRUH! ^^^^^^^^",
									"color: #f518f5; font-weight: bold; fonst-size: 200%;"
								);
								callCallback(callback, true, json, xhr);
							} else {
								callCallback(callback, false, json, xhr);
							}
						} catch (err) {
							console.log("error", err);
							console.log(
								"%cBRUH! ?????",
								"color: #f518f5; font-weight: bold; fonst-size: 200%;"
							);
							callCallback(callback, true, {}, xhr);
						}
					} else {
						console.log(
							"%cUser does not have access!",
							"color: #f56042; font-weight: bold; fonst-size: 120%;"
						);
						if (xhr.status == 503) {
							window.makeAlert("Please disable your VPN", "error");
						} else {
							window.makeAlert("Unknown error (0)", "error");
						}

						callCallback(callback, true, {}, xhr);
					}
				}
			};
			xhr.send(
				JSON.stringify({
					command: command,
					token: token,
					data: data
				})
			);
		} catch {
			callback(true, {}, null);
		}
	}
	request();
}
