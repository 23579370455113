import { Link as RouterLink, useHistory } from "react-router-dom";

import { FormHelperText } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TouchRipple from "@material-ui/core/ButtonBase/TouchRipple";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex"
	},

	tableContainer: {
		width: "95%",
		maxHeight: 440
	},
	clickableTableRow: {
		cursor: "pointer"
	}
}));

export default function SimpleTable(props) {
	const history = useHistory();
	const {
		rows = [],
		cols = [],
		link,
		linkTargets,
		hover,
		captionText,
		centered
	} = props;
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<TableContainer
				className={classes.tableContainer}
				component={Paper}
				elevation={2}
			>
				<Table>
					{captionText ? <caption> {captionText} </caption> : null}
					<TableHead>
						<TableRow>
							{cols.map((col, i) => {
								return (
									<TableCell key={col.id}>
										{col.label}
									</TableCell>
								);
							})}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, i) => {
							if (link) {
								return (
									<TableRow
										key={i}
										onClick={() => {
											history.push(linkTargets[i]);
										}}
										className={classes.clickableTableRow}
										hover={true}
									>
										{Object.keys(row).map((col, j) => {
											return (
												<TableCell key={j}>
													{row[col]}
												</TableCell>
											);
										})}
									</TableRow>
								);
							}
							return (
								<TableRow
									key={i}
									hover={hover}
									className={classes.tableRow}
								>
									{Object.keys(row).map((col, j) => {
										return (
											<TableCell key={j}>
												{row[col]}
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}
