import AppContext from "../../context";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import Typography from "@material-ui/core/Typography";
import formatNumber from "../../utils/format-number";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	classPage: {
		marginTop: theme.spacing(6),
		marginInline: theme.spacing(6),
		// set color to white if dark mode is enabled
		color: theme.palette.type === "dark" ? "white" : "black",
	},
	classSection: {
		marginTop: theme.spacing(2),
	},
	classSectionItem: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	fab: {
		position: "fixed",
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
}));

const months = ["Not started", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export default function Overview(props) {
	const { teamInfo } = props;
	const classes = useStyles();
	const { user, setUser } = React.useContext(AppContext);
	let i;
	const stores = Object.keys(teamInfo.stores);
	const lastMonth = Math.max(...Object.keys(teamInfo.stores[stores[0]].outcomes));
	const history = useHistory();
	let netProfit = 0;
	let positiveNetProfit = 0;
	let bank = 0;
	let productSales = {};
	let productColors = {};
	let totalSales = 0;
	const productNumbers = [1, 2, 3, 4];
	let store;
	let storeColors = {};
	let num;
	let oldStores = 0;
	for (i in stores) {
		store = stores[i];
		if (Object.keys(teamInfo.stores[store].outcomes).length === 1) {
			continue;
		}
		netProfit += parseInt(teamInfo.stores[store].breakdowns[lastMonth].netProfit);
		if (parseInt(teamInfo.stores[store].breakdowns[lastMonth].netProfit) > 0) {
			positiveNetProfit += parseInt(teamInfo.stores[store].breakdowns[lastMonth].netProfit);
		}
		bank += teamInfo.stores[store].outcomes[lastMonth].Bank;
		oldStores += 1;
		for (num of productNumbers) {
			if (teamInfo.stores[store].outcomes[lastMonth]["Product" + num.toString()] == "None") {
				continue;
			}
			if (
				Object.keys(productSales).includes(
					teamInfo.stores[store].outcomes[lastMonth]["Product" + num.toString()]
				)
			) {
				productSales[teamInfo.stores[store].outcomes[lastMonth]["Product" + num.toString()]] +=
					teamInfo.stores[store].outcomes[lastMonth]["ProductSales" + num.toString()];
			} else {
				productSales[teamInfo.stores[store].outcomes[lastMonth]["Product" + num.toString()]] =
					teamInfo.stores[store].outcomes[lastMonth]["ProductSales" + num.toString()];
				productColors[teamInfo.stores[store].outcomes[lastMonth]["Product" + num.toString()]] =
					"#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0");
			}
			totalSales += teamInfo.stores[store].outcomes[lastMonth]["ProductSales" + num.toString()];
		}
		storeColors[store] = "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0");
	}

	console.log("product colors", productColors);

	return (
		<Fade in timeout={500}>
			<div className={classes.classPage}>
				<Typography variant="h3">Hi {user.firstName}!</Typography>
				{oldStores === 0 ? (
					<div className={classes.classSection}>
						<Typography variant="h4">
							Get started by going into your stores, and submitting your first decisions.
						</Typography>
					</div>
				) : (
					<div style={{ marginTop: 5 }} >
						<Typography variant="h6">
							Team {teamInfo.team} performance for {months[lastMonth]}
						</Typography>
						<div className={classes.classSection}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6}>
									<Card style={{ width: "100%" }}>
										<CardContent>
											<Typography color="textSecondary" variant="button" gutterBottom>
												Net profit
											</Typography>
											<Typography variant="h4">{formatNumber(netProfit, true)}</Typography>
											<div
												style={{
													marginLeft: "-20px",
													marginRight: "-20px",
												}}
											>
												{stores.map((store) => (
													<hr
														style={{
															height: "3px",
															display: "inline-block",
															width:
																Math.round(
																	100 *
																	((teamInfo.stores[store].breakdowns[lastMonth]
																		? teamInfo.stores[store].breakdowns[lastMonth]
																			.netProfit
																		: 0) /
																		positiveNetProfit)
																).toString() + "%",
															borderWidth: 0,
															color: storeColors[store],
															backgroundColor: storeColors[store],
														}}
													></hr>
												))}
											</div>
											<List
												style={{
													margin: "-20px",
													marginBottom: "-35px",
												}}
											>
												{stores.map((store) => (
													<ListItem
														button
														divider
														onClick={() => history.push("/stores/" + store.toString())}
													>
														<ListItemIcon>
															<svg height="40" width="10">
																<line
																	x1="0"
																	y1="0"
																	x2="0"
																	y2="40"
																	stroke={storeColors[store]}
																	style={{
																		strokeWidth: 10,
																	}}
																/>
															</svg>
														</ListItemIcon>
														<ListItemText primary={"Store " + store.toString()} />
														<Typography variant="body1">
															{formatNumber(
																teamInfo.stores[store].breakdowns[lastMonth]
																	? teamInfo.stores[store].breakdowns[lastMonth].netProfit
																	: 0,
																true
															)}
														</Typography>
													</ListItem>
												))}
											</List>
										</CardContent>
									</Card>
								</Grid>
								<Grid item xs={12} md={6}>
									<Card style={{ width: "100%", minHeight: "90px" }}>
										<CardContent>
											<Typography color="textSecondary" variant="button" gutterBottom>
												Units sold
											</Typography>
											<Typography variant="h4">{totalSales}</Typography>
											<div
												style={{
													marginLeft: "-20px",
													marginRight: "-20px",
												}}
											>
												{Object.keys(productColors).map((product) => (
													<hr
														style={{
															height: "3px",
															display: "inline-block",
															width:
																(100 * (productSales[product] / totalSales)).toString() +
																"%",
															borderWidth: 0,
															color: productColors[product],
															backgroundColor: productColors[product],
														}}
													></hr>
												))}
											</div>
											<List
												style={{
													margin: "-20px",
													marginBottom: "-35px",
												}}
											>
												{Object.keys(productSales).map((product) => productSales[product] ? (
													<ListItem divider>
														<ListItemIcon>
															<svg height="40" width="10">
																<line
																	x1="0"
																	y1="0"
																	x2="0"
																	y2="40"
																	stroke={productColors[product]}
																	style={{
																		strokeWidth: 10,
																	}}
																/>
															</svg>
														</ListItemIcon>
														<ListItemText primary={product} />
														<Typography variant="body1">{productSales[product]}</Typography>
													</ListItem>
												) : null)}
											</List>
										</CardContent>
									</Card>
								</Grid>
							</Grid>
						</div>
					</div>
				)}
			</div>
		</Fade >
	);
}
