
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import React from "react";
import AppContext from "../context";
import { Button, TextField } from "@material-ui/core";
import { ThemeProvider, unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";

const CLIENT_ID =
	"450837736841-32h26l1u1lgu50ka39gig2etiebrl0h6.apps.googleusercontent.com";

const useStyles = makeStyles((theme) => ({
	mainContent: {
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		paddingTop: "100px",
		color: theme.palette.text.primary
	},
	toolbarRight: {
		display: "flex",
		alignItems: "center",
		marginLeft: "auto"
	},
	dollarIcon: {
		height: "90%",
		width: "90%"
	},
	WCommerce: {
		color: "#FCC000"
	}
}));

function queryParam(param) {
	var field = param;
	var url = window.location.href;
	if (url.indexOf("?" + field + "=") != -1) {
		return true;
	} else if (url.indexOf("&" + field + "=") != -1) {
		return true;
	}
	return false;
}

export default function Login(props) {
	const { user, setUser } = React.useContext(AppContext);
	const [code, setCode] = React.useState("");

	React.useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const myParam = urlParams.get('token');
		if (myParam) {
			try {
				setUser(JSON.parse(atob(myParam)));
				window.makeAlert("Login succeeded", "success")
			} catch (e) {
				window.makeAlert("Login error (Invalid token)", "error")
			}
		}
	}, [setUser])

	const classes = useStyles();

	document.title = "Please log in - Retail Simulation";

	async function onSandboxSignIn() {
		console.log("Code: " + code);
		// NProgress.start();
		const response = await fetch(`/SandboxSignIn`, { method: "POST", body: JSON.stringify({ code: code }) });
		// NProgress.done();
		if (response.status === 200) {
			const newUser = await response.json()
			setUser(newUser)
			window.makeAlert("Login succeeded", "success")
		} else if (response.status === 404) {
			window.makeAlert("Incorrect code", "error")
		} else {
			window.makeAlert("Login error (1)", "error")
		}
	}

	return (
		<React.Fragment>
			<AppBar position="fixed">
				<Toolbar>
					<Typography variant="h6">Retail Simulation</Typography>
					<div className={classes.toolbarRight}>
						<ThemeProvider theme={createMuiTheme({
							palette: {
								type: "dark",
								primary: {
									main: "#222222",
								},
								secondary: {
									main: "#FCC000",
								},
							},
						})}>
							<TextField
								label="Sandbox code"
								variant="outlined"
								color="secondary"
								value={code}
								onChange={(e) => setCode(e.target.value)}
								style={{ margin: 15 }}
								size="small"
								onKeyDown={(ev) => {
									console.log(`Pressed keyCode ${ev.key}`);
									if (ev.key === 'Enter') {
										// Do code here
										ev.preventDefault();
										onSandboxSignIn();
									}
								}}
							// InputLabelProps={{ style: { color: "white" } }}
							/>
							<Button
								variant="contained"
								color="secondary"
								onClick={onSandboxSignIn}
							>
								Sign in
							</Button>
						</ThemeProvider>
					</div>
				</Toolbar>
			</AppBar>
			<Container className={classes.mainContent}>
				<Grid container spacing={10}>
					<Grid container item xs={12} spacing={3}>
						<Grid item xs={6}>
							<Typography variant="h2">Welcome to</Typography>
							<Typography
								variant="h2"
								className={classes.WCommerce}
							>
								Retail Simulation
							</Typography>
							<Typography variant="h4">
								Launch a clothing business in the virtual world.
								Learn how to run a business and put your
								entrepreneur skills to the test.
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<img
								src="/static/images/diagram2.svg"
								className={classes.dollarIcon}
							></img>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</React.Fragment>
	);
}
