import Figure, { FigureRow } from "../../components/figure.js";
import { Route, Switch as RouterSwitch, useHistory, useParams, useRouteMatch } from "react-router-dom";

import AppContext from "../../context";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import Divider from "@material-ui/core/Divider";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import formatNumber from "../../utils/format-number";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	classPage: {
		marginTop: theme.spacing(6),
		marginInline: theme.spacing(6),
		zIndex: 10,
		position: "absolute",
		top: 0,
		color: theme.palette.type === "dark" ? "white" : "black",
		left: 0,
		right: 0,
	},
	classSection: {
		paddingTop: theme.spacing(2),
	},
	classSectionItem: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	fab: {
		position: "fixed",
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	shine: {
		background: theme.palette.type === "light" ? "#f6f7f8" : "#2e2e2e",
		backgroundImage: theme.palette.type === "light" ? "linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%)" : "linear-gradient(to right, #2e2e2e 0%, #464646 20%, #2e2e2e 40%, #2e2e2e 100%)",
		backgroundRepeat: "no-repeat",
		backgroundSize: "800px 104px",
		WebkitAnimationDuration: "0.5s",
		WebkitAnimationFillMode: "forwards",
		WebkitAnimationIterationCount: "infinite",
		WebkitAnimationName: "placeholderShimmer",
		WebkitAnimationTimingFunction: "linear",
		animationDuration: "0.5s",
		animationFillMode: "forwards",
		animationIterationCount: "infinite",
		animationName: "placeholderShimmer",
		animationTimingFunction: "linear",
	},
}));

const months = ["Not started", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export default function LoadingOverview(props) {
	const classes = useStyles();
	const { user, setUser } = React.useContext(AppContext);

	return (
		<Fade
			in={!props.teamInfoLoaded}
		>
			<div className={classes.classPage} style={{
				// width: props.mobileMode ? "100%" : "calc(100% - 72px)", 
				zIndex: 10,
				position: "absolute",
				top: 0,
				left: 0,
				right: 0
			}}>
				<Typography variant="h3">Hi {user.firstName}!</Typography>
				<div>
					<Typography variant="h6" className={classes.shine} style={{ width: "350px", height: "32px" }} />
					<div className={classes.classSection}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<Card style={{ width: "100%" }}>
									<CardContent>
										<Typography color="textSecondary" variant="button" gutterBottom>
											Net profit
										</Typography>
										<Typography
											variant="h4"
											className={classes.shine}
											style={{
												width: "200px",
												height: "1.2em",
											}}
										/>
										<div
											style={{
												marginLeft: "-20px",
												marginRight: "-20px",
											}}
										>
											<hr
												style={{
													height: "3px",
													display: "inline-block",
													width: "100%",
													borderWidth: 0,
												}}
												className={classes.shine}
											></hr>
										</div>
										<List
											style={{
												margin: "-20px",
												marginBottom: "-35px",
											}}
										>
											<ListItem button divider>
												<ListItemIcon>
													<svg height="40" width="10" className={classes.shine}>
														<line
															x1="0"
															y1="0"
															x2="0"
															y2="40"
															style={{
																strokeWidth: 10,
															}}
														/>
													</svg>
												</ListItemIcon>
												<ListItemText
													// primary={
													// 	"Store 0"
													// }
													className={classes.shine}
													// style={{ float: "left" }}
													style={{ height: "24px" }}
												/>
											</ListItem>
										</List>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12} md={6}>
								<Card style={{ width: "100%" }}>
									<CardContent>
										<Typography color="textSecondary" variant="button" gutterBottom>
											Units sold
										</Typography>
										<Typography
											variant="h4"
											className={classes.shine}
											style={{
												width: "200px",
												height: "1.2em",
											}}
										/>
										<div
											style={{
												marginLeft: "-20px",
												marginRight: "-20px",
											}}
										>
											<hr
												style={{
													height: "3px",
													display: "inline-block",
													width: "100%",
													borderWidth: 0,
												}}
												className={classes.shine}
											/>
										</div>
										<List
											style={{
												margin: "-20px",
												marginBottom: "-35px",
											}}
										>
											{[...Array(4).keys()].map((product) => (
												<ListItem divider key={product}>
													<ListItemIcon>
														<svg height="40" width="10" className={classes.shine}>
															<line
																x1="0"
																y1="0"
																x2="0"
																y2="40"
																style={{
																	strokeWidth: 10,
																}}
															/>
														</svg>
													</ListItemIcon>
													<ListItemText
														// primary="D. Womens Casual"
														style={{
															height: "24px",
														}}
														className={classes.shine}
													/>
												</ListItem>
											))}
										</List>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</div>
				</div>
			</div>
		</Fade>
	);
}
