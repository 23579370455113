import AppContext from "../../context";
import BackupIcon from "@material-ui/icons/Backup";
import Button from "@material-ui/core/Button";
import CachedIcon from "@material-ui/icons/Cached";
import CloudIcon from "@material-ui/icons/Cloud";
import Collapse from "@material-ui/core/Collapse";
import ConfirmDialouge from "../../components/simple-table.js";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Fab from "@material-ui/core/Fab";
import GetAppIcon from "@material-ui/icons/GetApp";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LoadingText from "../../components/loading.js";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import NProgress from "nprogress";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import callAPI from "../../utils/api.js";

const useStyles = makeStyles((theme) => ({
	thicc: {
		height: "100%",
		width: "100%",
	},
	fab: {
		position: "fixed",
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	noSelect: {
		userSelect: "none",
	},
}));

export default function Backups() {
	const { user, setUser } = React.useContext(AppContext);
	const classes = useStyles();
	const [dataLoaded, setDataLoaded] = React.useState(false);
	const [data, setData] = React.useState([]);
	const [openIndex, setOpenIndex] = React.useState(null);
	const [restoreBackupDialogueOpen, setRestoreBackupDialogueOpen] =
		React.useState(false);

	const [selectedItem, setSelectedItem] = React.useState(
		"If you can see this, something has gone wrong."
	);
	const [sccessPopupOpen, setSccessPopupOpen] = React.useState(false);

	React.useEffect(function () {
		loadBackups();
	}, []);

	function loadBackups() {
		NProgress.start();
		setDataLoaded(false);
		callAPI("GetAvailableBackups", user.token, {}, function (err, res, xhr) {
			NProgress.done();

			if (!err) {
				console.log(res.backups);
				setData(res.backups);
				setDataLoaded(true);
			}
		});
	}

	function restoreBackup() {
		NProgress.start();
		setDataLoaded(false);
		callAPI(
			"RestoreBackup",
			user.token,
			{ filename: selectedItem },
			function (err, res, xhr) {
				NProgress.done();
				if (!err) {
					backupScucess();
					loadBackups();
				}
			}
		);
		setSelectedItem("");
		setOpenIndex(null);
	}

	function backupScucess() {
		setSccessPopupOpen(true);
		loadBackups();
	}

	function downloadBackup(itemName, callback) {
		let dbToken = itemName;
		callAPI(
			"DownloadBackup",
			user.token,
			{ filename: itemName },
			function (err, res, xhr) {
				if (!err) {
					dbToken = res.path;
					console.log(res.path);
					const url = "/Export/" + dbToken;
					callback(url);
				}
			}
		);
	}

	return (
		<div className={classes.thicc}>
			{dataLoaded ? (
				<List>
					{data.map((item, index) => {
						const text =
							"Backup at " +
							new Date(
								parseFloat(item) * 1 * 1000
							).toLocaleString();
						return (
							<div key={"backup-list-item-" + index}>
								<ListItem
									button
									onClick={() => {
										setOpenIndex(index);
									}}
									selected={openIndex === index}
								>
									<ListItemIcon>
										<CloudIcon />
									</ListItemIcon>
									<ListItemText primary={text} />
									{openIndex === index ? (
										<ExpandLess />
									) : (
										<ExpandMore />
									)}
								</ListItem>
								<Collapse
									in={openIndex === index}
									unmountOnExit
								>
									<Tooltip title="Restore database backup">
										<Button
											onClick={() => {
												setRestoreBackupDialogueOpen(
													true
												);
												setSelectedItem(item);
											}}
											variant="contained"
											color="secondary"
											startIcon={<BackupIcon />}
										>
											Restore
										</Button>
									</Tooltip>
									<Tooltip title="Download database backup">
										<Button
											onClick={() => {
												setSelectedItem(item);
												downloadBackup(
													item,
													function (url) {
														window.open(
															url,
															"Downloading " +
															item +
															"..."
														);
													}
												);
											}}
											variant="contained"
											color="primary"
											startIcon={<GetAppIcon />}
										>
											Download
										</Button>
									</Tooltip>
								</Collapse>
							</div>
						);
					})}
				</List>
			) : (
				<LoadingText />
			)}
			<Tooltip title="Reload backups">
				<Fab
					color="secondary"
					onClick={() => {
						loadBackups(user.token);
					}}
					className={classes.fab}
				>
					<CachedIcon />
				</Fab>
			</Tooltip>
			<ConfirmDialouge
				open={restoreBackupDialogueOpen}
				setOpen={setRestoreBackupDialogueOpen}
				title="Are you sure you want to restore this backup?"
				body={
					<span>
						Restoring a backup of the database will effect{" "}
						<b>
							EVERY <i>CLASS</i>, <i>STUDENT</i> AND{" "}
							<i>TEACHER</i>
						</b>{" "}
						who is registered in the system.{" "}
						<b>YOU CANNOT UNDO THIS ACTION!</b> Are you{" "}
						<i>
							<b>COMPLETELY SURE</b>
						</i>{" "}
						that you wish to continue?
					</span>
				}
				confirmButtonText="Restore backup"
				cancelButtonText="Go back"
				onConfirm={() => {
					restoreBackup();
				}}
				onCancel={() => { }}
			/>
			<Snackbar
				autoHideDuration={2500}
				onClose={() => setSccessPopupOpen(false)}
				open={sccessPopupOpen}
			>
				<MuiAlert elevation={6} variant="filled" severity="success">
					Successfully restored database backup {selectedItem}!
				</MuiAlert>
			</Snackbar>
		</div>
	);
}
