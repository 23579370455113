import AppContext from "../../context";
import Button from "@material-ui/core/Button";
import ConfirmDialouge from "../../components/confirm-dialouge.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import React from "react";
import SendIcon from "@material-ui/icons/Send"; // send SQL icon
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import NProgress from "nprogress";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import callAPI from "../../utils/api.js";

const useStyles = makeStyles((theme) => ({
	mainContent: {
		height: "100%",
		width: "auto",
		maxWidth: "90%",
		display: "flex",
		flexDirection: "column",
		padding: theme.spacing(3),
	},
	sqlContainer: {
		width: "100%",
		height: "100%",
	},
	sqlInput: {
		width: "100%",
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		display: "flex",
	},
	sqlInputBox: {
		width: "90%",
	},
	sqlOutput: {
		height: "auto",
		minHeight: 500,
		maxWidth: "90%",
		overflow: "auto",
		transform: "rotateX(180deg)",
		borderRadius: theme.shape.borderRadius,
	},
	sqlTable: {
		minWidth: 125,
		transform: "rotateX(180deg)",
	},
	hideMe: {
		display: "none",
	},
}));

export default function SQLTerminal() {
	const classes = useStyles();
	const { user, setUser } = React.useContext(AppContext);
	const [sqlDataLoaded, setSqlDataLoaded] = React.useState(true);
	const [sqlData, setSqlData] = React.useState([
		['"Nothing here yet. Try sending a query!"'],
	]);
	const [writeModeEnabled, setWriteModeEnabled] = React.useState(false);
	const [writeModeDialogueOpen, setWriteModeDialogueOpen] =
		React.useState(false);
	const sqlInput = React.useRef(null);

	function sendSqlQuery() {
		NProgress.start();
		sqlInput.current.focus();
		sqlInput.current.select();
		let query = sqlInput.current.value;
		callAPI(
			"RunSqlQuery",
			user.token,
			{ write: writeModeEnabled, query: query },
			function (err, res, xhr) {
				NProgress.done();
				if (!err) {
					setSqlData(res);
				} else {
					console.warn("Error submitting SQL Querry :(");
					setSqlData([
						["An error occured. It was most likely your fault ;)"],
					]);
				}
				setSqlDataLoaded(true);
			}
		);
	}

	return (
		<React.Fragment>
			<div className={classes.mainContent}>
				<Typography variant="h5">SQL Terminal</Typography>

				<div className={classes.sqlContainer}>
					<form
						className={classes.sqlInput}
						id="sqlForm"
						noValidate
						autoComplete="off"
						onSubmit={(e) => {
							e.preventDefault();
							setSqlDataLoaded(false);
							sendSqlQuery();
						}}
					>
						<TextField
							className={classes.sqlInputBox}
							ref={sqlInput}
							variant="filled"
							spellCheck="false"
							label="Type a SQL query here"
							placeholder="SELECT * FROM Classes"
							helperText="Please try not to corrupt the database"
						/>
						<span className={classes.sqlInputSubmit}>
							<Tooltip title="Submit query">
								<label
									htmlFor="sqlFormSubmitBtn"
									onClick={() => {
										setSqlDataLoaded(false);
										sendSqlQuery();
									}}
								>
									<input
										type="submit"
										id="sqlFormSubmitBtn"
										className={classes.hideMe}
									/>
									<IconButton
										color="secondary"
										fontSize="large"
									>
										<SendIcon />
									</IconButton>
								</label>
							</Tooltip>
						</span>
					</form>
					<TableContainer
						component={Paper}
						elevation={6}
						className={classes.sqlOutput}
					>
						<Table className={classes.sqlTable} size="small">
							<TableBody>
								{sqlDataLoaded ? (
									sqlData.map((row, rowIndex) => {
										return (
											<TableRow
												key={"sqlTableRow" + rowIndex}
											>
												{row.map((col, colIndex) => {
													return (
														<TableCell
															key={
																"sqlTableRow" +
																rowIndex +
																"col" +
																colIndex
															}
														>
															{JSON.stringify(
																col
															)}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})
								) : (
									<TableRow>
										<TableCell align="center">
											Looooooooooooooooooooooooooooooooooding
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<FormControlLabel
						control={
							<Switch
								checked={writeModeEnabled}
								onClick={() => {
									if (!writeModeEnabled) {
										setWriteModeDialogueOpen(true);
									} else {
										setWriteModeEnabled(false);
									}
								}}
								color="secondary"
							/>
						}
						label="Write mode"
					/>
				</div>
			</div>
			<ConfirmDialouge
				open={writeModeDialogueOpen}
				setOpen={setWriteModeDialogueOpen}
				title="Enable write mode?"
				body={
					<span>
						Selecting this option will enable database write mode.
						This means that anything you submit here will affect the
						live database. <i>Please try not to corrupt it.</i>
					</span>
				}
				confirmButtonText="Enable"
				onConfirm={() => {
					setWriteModeEnabled(true);
				}}
				onCancel={() => {
					setWriteModeEnabled(false);
				}}
			/>
		</React.Fragment>
	);
}
