import App from "./app";
import ContextProvider from "./context/context-provider";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

Sentry.init({
	dsn: "https://7ade5de240764bac9ceffb7da387394f@o1158456.ingest.sentry.io/6241621",
	integrations: [new Integrations.BrowserTracing()],

	tracesSampleRate: 1.0,

	beforeSend(event, hint) {
		if (event.exception) { Sentry.showReportDialog({ eventId: event.event_id }); }
		return event;
	},
})

ReactDOM.render(
	<React.StrictMode>
		<ContextProvider>
			<App />
		</ContextProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// serviceWorker.register();
serviceWorkerRegistration.register();